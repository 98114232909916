import "react-perfect-scrollbar/dist/css/styles.css";

import "animate.css/animate.min.css";
import "nprogress/nprogress.css";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/index.css";
// import './__mocks__';
import { StyledEngineProvider } from "@mui/material/styles";
import React, { StrictMode } from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./contexts/JWTContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { TradingViewProvider } from "./contexts/TradingViewContext";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import * as Sentry from "@sentry/react";

Bugsnag.start({
  apiKey: "6052a1f260044bc98a6fa7e73ea20d1d",
  plugins: [new BugsnagPluginReact()],
});

Sentry.init({
  dsn: "https://77572c869264148fa3eadca70c5eeae9@o4507940454596608.ingest.us.sentry.io/4507965984342016",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.ebinex\.com/, /^https:\/\/api-testnet\.ebinex\.com/],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <StrictMode>
      <HelmetProvider>
        <CookiesProvider>
          <StyledEngineProvider injectFirst>
            <SettingsProvider>
              <LayoutProvider>
                <BrowserRouter>
                  <AuthProvider>
                    <TradingViewProvider>
                      <App />
                    </TradingViewProvider>
                  </AuthProvider>
                </BrowserRouter>
              </LayoutProvider>
            </SettingsProvider>
          </StyledEngineProvider>
        </CookiesProvider>
      </HelmetProvider>
    </StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
